.nav {
    display: flex;
    gap: 25px;
}

.link {
    padding: 17px 8px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    text-decoration: none;
}

.link_active {
    box-shadow: inset 0 -2px 0 0 #6076e5;
}

.link_active > a {
    color: #6076e5
}


.link_wrapper {
    display: inline-flex;
    position: relative;
    white-space: nowrap;
    margin-right: 8px;
    align-items: center;
    outline: 0;
    height: 100%;
    cursor: pointer;
}