.footer_copyright {
    margin-top: 10px;
    display: flex;
    justify-content: center
}

.link_a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
}