.main {
    display: flex;
    gap: 15px;
    min-height: 1000px;
    margin-top: 16px;
}

.root {
    /*min-width: 900px;*/
    /*max-width: 1020px;*/
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
