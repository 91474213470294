.item {
    border: 1px solid;
    margin-top: 20px;
    background: #fff;
    border-radius: 3px;
}

.item_content {
    padding: 18px;

}

.item_title {
    padding: 16px 24px;
    border-bottom: 1px solid #e9e9e9;
    background-color: #f9f9fb;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    color: #000000;
    font-size: 18px;
}

.item_title > h2 {
    font-size: 18px;
}

.item_icon {
    display: flex;
    gap: 5px;
    align-items: center;
}

.item_button {
    background-color: #6076e5;
    color: #fff;
    border-style: none;
}

.item_button > a {
    text-decoration: none;
    color: #fff;
    line-height: 40px;
    padding: 0 24px;
}
.item_margin {
margin-top: 12px
}

.item_image {
    max-width: 300px;
    max-height: 300px;
}