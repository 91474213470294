.header {
    display: flex;
    gap: 15px;
}

.header_new {
    width: 100%;
    height: 64px;
    background: #fff;

}

.main_header {
    width: 45%;
    height: 200px;
}

.header_logo {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-right: 100px;
    color: #000;
    text-decoration: none;
}

.header_wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    box-sizing: border-box;
    z-index: 1;

}